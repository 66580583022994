import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { siteMetadata } from "../../gatsby-config";
import Layout from "../components/layout";
import { SectionAnimated, DivAnimated } from "../components/AnimatedSection";
import {
  hero,
  design,
  design__media,
  img_caption,
  img_caption_overlay,
  intro,
  overviewCaseStudy,
  overviewCaseStudy__content,
  overviewCaseStudy__title,
  overviewList,
  subtitle,
  title,
} from "../components/project.module.scss";

const ProjectCuPage = () => {
  const imgVariants = {
    animate: { opacity: 1, scale: 1, y: 0 },
    initial: {
      opacity: 0,
      scale: 0.95,
      y: 0,
    },
  };
  return (
    <>
      <Helmet>
        <body className="dark-header" />
        <title>CU</title>
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <div className="container-fluid" style={{ padding: 0 }}>
          <div style={{ background: "#1d4f91" }}>
            <div className="row">
              <DivAnimated cn={title}>
                <h1 style={{ color: "#ffffff" }}>
                  Columbia University Medical Center
                </h1>
              </DivAnimated>
              <div className="col-12">
                <DivAnimated cn={intro}>
                  <h2 style={{ color: "#ffffff" }} className={subtitle}>
                    UI Design System + Drupal CMS Drag and Drop Website Builder
                  </h2>
                </DivAnimated>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <motion.div
                  variants={imgVariants}
                  initial="initial"
                  animate="animate"
                  transition={{ duration: 0.8, ease: "easeIn" }}
                >
                  <StaticImage
                    src="../images/teaser-cu.webp"
                    quality="100"
                    loading="eager"
                    placeholder="blurred"
                    layout="fullWidth"
                    className={hero}
                    alt="Desktop and mobile designs of department websites"
                  />
                </motion.div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ color: "#ffffff" }}>
                <section className={overviewList}>
                  <div className="col-md-6">
                    <h3>Project</h3>
                    <p>
                      UX/UI modernization of 100+ websites across the institution's medical
                      schools, research departments, and patient care services.
                    </p>
                    <h3>Role</h3>
                    <p>UI/UX Designer &amp; Frontend Developer</p>
                    <h3>Responsibilities</h3>
                    <p>
                      User Interface Design, Frontend Development, Web Style
                      Guide Management, Prototyping, QA Testing, User Research,
                      Wireframing
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h3>Tools</h3>
                    <p>
                      Sketch, Adobe XD, InVision, HTML/CSS, Sass, jQuery, Git,
                      Bitbucket, Drupal CMS, Pattern Lab, Browserstack, Jira,
                      Illustrator, Photoshop
                    </p>
                    <h3>Team</h3>
                    <p>
                      1 Design Manager, 2 Frontend Developers/Designers, 1
                      Technical Director, 2 Backend Developers, 2 DevOps
                      Engineers, 1 Manager, 1 Project Manager, 1 Data Manager, 1
                      Product Director, 1 Product Manager, 2 Content Writers, 1
                      Content Producer
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SectionAnimated cn={overviewCaseStudy}>
                <div className={overviewCaseStudy__title}>
                  <h2>Component-Based Design System</h2>
                </div>
                <div className={overviewCaseStudy__content}>
                  <p>
                    At Columbia University Medical Center's in-house web team,
                    we developed a design system and a custom Drupal CMS for the
                    institution's medical schools and research departments. Main
                    design goals were to standardize and modernize the digital
                    brand, user interface, and user experience across
                    departments websites.
                  </p>
                  <div>
                    <StaticImage
                      src="../images/cu-old-sites.webp"
                      quality="100"
                      layout="fullWidth"
                      placeholder="blurred"
                      alt="Screenshots of department websites before their redesigns"
                    />
                    <p className={img_caption}>
                      Examples of Columbia University department websites before
                      their redesigns.
                    </p>
                  </div>
                  <p>
                    With a component-based design system, designers and
                    developers could create or improve features more efficiently
                    for new projects. Reusable code and UI components ensured
                    more consistent design implementations and helped minimize
                    duplication. Having an accompanying development prototyping
                    tools, such as Pattern Lab, also enabled the frontend design
                    team to prototype rapidly in code.
                  </p>
                  <p>
                    Based on the design system's shared guidelines, we developed
                    a CMS in Drupal with drag and drop page building
                    functionalities for content creators across Columbia.
                    Besides various theme options, components, and widgets could
                    be added to different layout regions and, as a result, it
                    became easier for editors to create customized pages.
                  </p>
                </div>
              </SectionAnimated>
            </div>
          </div>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/cu-desktop-1.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#1d4f91" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/cu-mobile-1.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Mobile designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/cu-desktop-2.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated
            cn={design}
            style={{ background: "#1d4f91" }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.8,
                duration: 1,
              },
            }}
          >
            <div className={design__media}>
              <StaticImage
                src="../images/cu-mobile-2.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Mobile designs of department websites"
              />
            </div>
          </DivAnimated>
          <div className="row">
            <div className="col-12">
              <SectionAnimated cn={overviewCaseStudy}>
                <div className={overviewCaseStudy__title}>
                  <h2>Development / Design Process and Major Lessons</h2>
                </div>
                <div className={overviewCaseStudy__content}>
                  <p>
                    Initially, we designed the UI and wireframes in Sketch and
                    Adobe XD and used InVision for creating interactive
                    prototypes. When the design system grew more robust with
                    well-defined standardized elements (typography, borders,
                    background colors, spacing, etc.), we used Pattern Lab as
                    the primary prototyping tool as well as a living style
                    guide.
                  </p>
                  <p>
                    Pattern Lab is a static site generator that promotes atomic
                    design principles, i.e., the use of small "atoms" such as a
                    button or form field to create larger "molecules" and
                    "organisms" like a search box or a contact form. By
                    utilizing this atomic-based design approach in code using
                    variables and mixins, the development/design process became
                    much faster. However, since certain complex components, such
                    as our CTAs, needed to work for many different content
                    scenarios, some of these organisms and molecules that often
                    repeat throughout the design system required more custom
                    styles/code.
                  </p>
                  <p>
                    Working within a small in-house development team for a large
                    organization, the main challenge was maintaining hundreds of
                    different legacy web properties with separate code bases. By
                    implementing a new design system based on one code base, we
                    could quickly implement features, bug fixes, and updates
                    across different department websites. Furthermore, with
                    standardized CMS templates and drag and drop page builder
                    tool, each website could also easily be customized for
                    different types of content.
                  </p>
                  <p>
                    At first, we created a very flexible system where editors
                    could add components and styles to almost any region of a
                    template (sidebar, body content, homepage, etc.). As
                    requirements changed over time and more departments were
                    using our product, we learned that highly customized designs
                    weren't an essential need for our users. Furthermore, since
                    the institution was moving towards a unified brand for print
                    and web, it was better to simplify the design system with a
                    core set of reusable components and layout styles.
                  </p>
                </div>
              </SectionAnimated>
            </div>
          </div>
          <DivAnimated cn={design} style={{ background: "#2d2e31" }}>
            <figure className={design__media}>
              <StaticImage
                src="../images/cu-design-process.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Screenshots of "
              />
              <div className={img_caption_overlay}>
                <figcaption>
                  Design prototypes in
                  Pattern Lab and Sketch
                </figcaption>
              </div>
            </figure>
          </DivAnimated>
        </div>
      </Layout>
    </>
  );
};

export default ProjectCuPage;
